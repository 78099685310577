import { Alert, AlertIcon, AlertToastContainer, AlertVariants, AlertWithButton, InlineAlert } from '$packages/clay-alert/docs/index';
import * as React from 'react';
export default {
  Alert,
  AlertIcon,
  AlertToastContainer,
  AlertVariants,
  AlertWithButton,
  InlineAlert,
  React
};