import { CardBasic, CardBasicHorizontal, CardWithInfo, CardWithNavigation, CardWithUser, CardWithHorizontal, Card } from '$packages/clay-card/docs/index';
import * as React from 'react';
export default {
  CardBasic,
  CardBasicHorizontal,
  CardWithInfo,
  CardWithNavigation,
  CardWithUser,
  CardWithHorizontal,
  Card,
  React
};