/**
 * SPDX-FileCopyrightText: © 2023 Liferay, Inc. <https://liferay.com>
 * SPDX-License-Identifier: BSD-3-Clause
 */

import {useControlledState} from '@clayui/shared';
import React, {useCallback, useMemo} from 'react';

import {ChildrenFunction, Collection} from '../collection';
import {createImmutableTree} from '../tree-view/useTree';
import {Scope, ScopeContext} from './ScopeContext';
import {BodyContext, useTable} from './context';

type Props<T> = {
	/**
	 * Children content to render a dynamic or static content.
	 */
	children: React.ReactNode | ChildrenFunction<T, unknown>;

	/**
	 * Property to set the initial value of items (uncontrolled).
	 */
	defaultItems?: Array<T>;

	/**
	 * Property to render content with dynamic data (controlled).
	 */
	items?: Array<T>;

	/**
	 * A callback which is called when the property of items is changed (controlled).
	 */
	onItemsChange?: (items: Array<T>) => void;
} & React.TableHTMLAttributes<HTMLTableSectionElement>;

type ItemProps<T> = {
	children: React.ReactElement;
	item: T;
	keyValue: React.Key;
};

// TODO: Move the implementation to the Collection and use the benefits of
// generator to pause the iteration and return later with a form of
// yield CPU time for the browser.
function* flatten<T extends Record<string, any>>(
	array: Array<T>,
	expandedKeys: Set<React.Key>,
	nestedKey: string,
	itemKey: string,
	level = 1,
	loc: Array<number> = []
): IterableIterator<T> {
	for (let i = 0; i < array.length; i++) {
		const item = {
			...array[i],
			_index: i,
			_level: level,
			_loc: [...loc, i],
			_size: array.length,
		} as unknown as T;

		if (
			Array.isArray(array[i]![nestedKey]) &&
			array[i]![nestedKey].length > 0
		) {
			delete item[nestedKey];
			// @ts-ignore
			item._expandable = true;
			yield item;

			// TODO: Make it dynamic using the key generated by the developer at
			// render time.
			if (expandedKeys.has(array[i]![itemKey])) {
				yield* flatten(
					array[i]![nestedKey],
					expandedKeys,
					nestedKey,
					itemKey,
					level + 1,
					item['_loc']
				);
			}
		} else {
			yield item;
		}
	}
}

function BodyInner<T extends Record<string, any>>(
	{
		children,
		defaultItems,
		items: outItems,
		onItemsChange,
		...otherProps
	}: Props<T>,
	ref: React.Ref<HTMLTableSectionElement>
) {
	const {expandedKeys, itemIdKey, nestedKey} = useTable();

	const [treeItems, setTreeItems] = useControlledState({
		defaultName: 'defaultItems',
		defaultValue: defaultItems ?? [],
		handleName: 'onItemsChange',
		name: 'items',
		onChange: onItemsChange,
		value: outItems,
	});

	const insert = useCallback(
		(path: Array<number>, value: unknown) => {
			const tree = createImmutableTree(treeItems, nestedKey!);

			tree.produce({op: 'add', path, value});

			setTreeItems(tree.applyPatches());
		},
		[treeItems]
	);

	const items = useMemo(() => {
		if (!nestedKey || !treeItems) {
			return treeItems;
		}

		return [...flatten(treeItems!, expandedKeys, nestedKey, itemIdKey)];
	}, [outItems, expandedKeys, nestedKey, itemIdKey]);

	return (
		<tbody {...otherProps} ref={ref}>
			<ScopeContext.Provider value={Scope.Body}>
				<BodyContext.Provider value={{insert}}>
					<Collection<T>
						connectNested={false}
						itemContainer={useCallback(
							({children, item, keyValue}: ItemProps<any>) =>
								item
									? React.cloneElement(children, {
											_expandable: item._expandable,
											_index: item._index,
											_item: item,
											_level: item._level,
											_loc: item._loc,
											_size: item._size,
											keyValue,
									  })
									: children,
							[]
						)}
						itemIdKey={itemIdKey}
						items={items}
						passthroughKey={false}
					>
						{children}
					</Collection>
				</BodyContext.Provider>
			</ScopeContext.Provider>
		</tbody>
	);
}

type ForwardRef = {
	displayName: string;
	<T>(
		props: Props<T> & {ref?: React.Ref<HTMLTableSectionElement>}
	): JSX.Element;
};

export const Body = React.forwardRef(BodyInner) as ForwardRef;

Body.displayName = 'TableBody';
