import { LoadingIndicator, LoadingIndicatorSquares, LoadingIndicatorPrimary, LoadingIndicatorSecondary, LoadingIndicatorLight, LoadingIndicatorExtraSmall, LoadingIndicatorSmall, LoadingIndicatorMedium, LoadingIndicatorLarge } from '$packages/clay-loading-indicator/docs/index';
import * as React from 'react';
export default {
  LoadingIndicator,
  LoadingIndicatorSquares,
  LoadingIndicatorPrimary,
  LoadingIndicatorSecondary,
  LoadingIndicatorLight,
  LoadingIndicatorExtraSmall,
  LoadingIndicatorSmall,
  LoadingIndicatorMedium,
  LoadingIndicatorLarge,
  React
};