import { ExampleTextHighlight, TextColorFont, TextItalicFont, TextMonospaceFont, TextSize, TextTruncateFont, TextWeight } from '$packages/clay-core/docs/text';
import * as React from 'react';
export default {
  ExampleTextHighlight,
  TextColorFont,
  TextItalicFont,
  TextMonospaceFont,
  TextSize,
  TextTruncateFont,
  TextWeight,
  React
};