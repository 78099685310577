/**
 * SPDX-FileCopyrightText: © 2019 Liferay, Inc. <https://liferay.com>
 * SPDX-License-Identifier: BSD-3-Clause
 */

import ClayPagination from './Pagination';
import {ClayPaginationWithBasicItems} from './PaginationWithBasicItems';

export {ClayPaginationWithBasicItems};

export default ClayPagination;
