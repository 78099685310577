/**
 * SPDX-FileCopyrightText: © 2019 Liferay, Inc. <https://liferay.com>
 * SPDX-License-Identifier: BSD-3-Clause
 */

/**
 * @deprecated since version 3.54.x.
 */

import ClayManagementToolbar from './ManagementToolbar';
import ClayResultsBar from './ResultsBar';

export {ClayResultsBar};
export default ClayManagementToolbar;
