import { Icon, IconWithContext } from '$packages/clay-icon/docs/index';
import IconSearch from '$clayui.com/src/components/IconSearch';
import icons from '$clayui.com/static/js/icons-autogenerated.json';
import * as React from 'react';
export default {
  Icon,
  IconWithContext,
  IconSearch,
  icons,
  React
};