import { MultiSelectInput, MultiSelectInputWithAutocomplete, MultiSelectInputWithCustomAutocomplete, MultiSelectInputWithSelectButton, MultiSelectInputWithValidation, MultiSelectSmallInput, MultiSelectSmallInputWithSelectButton } from '$packages/clay-multi-select/docs/index';
import * as React from 'react';
export default {
  MultiSelectInput,
  MultiSelectInputWithAutocomplete,
  MultiSelectInputWithCustomAutocomplete,
  MultiSelectInputWithSelectButton,
  MultiSelectInputWithValidation,
  MultiSelectSmallInput,
  MultiSelectSmallInputWithSelectButton,
  React
};